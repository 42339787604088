export default class Avaliacao {
    constructor({
        descricao,
        conteudoProgramatico,
        peso,
        ordenacao,
        dataAvaliacao,
        dataRecuperacao,
        recuperacao,
        segmentacaoId,
        anoLetivoId,
        turmaId,
        disciplinaId,
        tipoAvaliacaoId
    }) {
        this.descricao = descricao
        this.conteudoProgramatico = conteudoProgramatico
        this.peso = peso
        this.ordenacao = ordenacao
        this.dataAvaliacao = dataAvaliacao
        this.dataRecuperacao = dataRecuperacao
        this.recuperacao = recuperacao
        this.segmentacaoId = segmentacaoId
        this.anoLetivoId = anoLetivoId
        this.turmaId = turmaId
        this.disciplinaId = disciplinaId
        this.tipoAvaliacaoId = tipoAvaliacaoId
    }
}