<template>
  <div>
    <titulo-avaliacoes />

    <filtro @filtrar="filtrar" @limparFiltro="limparTabela" />

    <div>
      <b-row class="justify-content-end">
        <b-col cols="12" md="auto">
          <b-button
            @click="openModal('avaliacao')"
            variant="info"
            class="my-4 mt-md-2 w-100"
            :disabled="desabilitar.btnCadastrar"
            >Cadastrar nova avaliação</b-button
          >
        </b-col>
      </b-row>

      <tabela
        :items="resultadoBusca"
        @editar="openModal"
        @excluir="confirmarExclusao"
      />

      <paginacao v-model="paginacao" :total="total" @input="filtrar" />
    </div>

    <modal
      :exibir="modais.avaliacao"
      :form="modais.avaliacaoDados"
      @salvar="salvar"
      @fechar="closeModal"
      :filtro="filtro"
    />
  </div>
</template>
<script>
// Utils & Aux:
import Avaliacao from '@/common/models/academico/Avaliacao';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import AvaliacoesService from '@/common/services/academico/avaliacoes.service.js';

// Components:
import Filtro from '@/views/academico/diario-classe/components/Opcao01';
import TituloAvaliacoes from '@/views/academico/diario-classe/components/avaliacoes/TituloAvaliacoes';
import Tabela from './components/avaliacoes/Tabela';
import Modal from './components/avaliacoes/Modal';
import Paginacao from '@/components/paginacao/Paginacao';

export default {
  components: {
    TituloAvaliacoes,
    Paginacao,
    Filtro,
    Tabela,
    Modal,
  },
  data() {
    return {
      filtro: {},
      filtrarPor: {},
      resultadoBusca: [],
      modais: {
        avaliacao: false,
        avaliacaoDados: {},
      },
      paginacao: { numeroDaPagina: 1, tamanhoDaPagina: 10 },
      total: 0,
      desabilitar: { btnCadastrar: true },
    };
  },
  watch: {
    filtro: 'verificarHabilitarCadastro',
  },
  methods: {
    // FUNÇÕES FILTRO:
    filtrar(filtroUtilizado) {
      this.filtro =
        filtroUtilizado.disciplinaId?.length > 0
          ? filtroUtilizado
          : this.filtro;

      if (!this.validarFiltro()) return;
      let filtroBackEnd = {
        segmentacaoId: this.filtro.segmentacaoId,
        turmaId: this.filtro.turmaId,
        disciplinaId: this.filtro.disciplinaId,
        ...this.paginacao,
      };
      this.$store.dispatch(START_LOADING);
      AvaliacoesService.obterPorFiltro(filtroBackEnd)
        .then(({ data }) => {
          this.resultadoBusca = data.itens;
          this.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    limparTabela() {
      this.filtro = {};
      this.paginacao = { numeroDaPagina: 1, tamanhoDaPagina: 10 };
      this.total = 0;
      this.resultadoBusca = [];
    },
    // FUNÇÕES DE VALIDAÇÃO:
    validarFiltro() {
      return this.filtro.anoLetivoId?.length > 0 &&
        this.filtro.segmentacaoId?.length > 0 &&
        this.filtro.disciplinaId?.length > 0 &&
        this.filtro.turmaId?.length > 0 &&
        this.filtro.disciplinaId?.length > 0
        ? true
        : false;
    },
    // FUNÇÕES CRUD:
    salvar() {
      // p/ criar aula:
      if (!this.modais.avaliacaoDados.id) {
        this.modais.avaliacaoDados.anoLetivoId = this.filtro.anoLetivoId;
        this.modais.avaliacaoDados.cursoId = this.filtro.cursoId;
        this.modais.avaliacaoDados.turmaId = this.filtro.turmaId;
        this.modais.avaliacaoDados.segmentacaoId = this.filtro.segmentacaoId;
        this.modais.avaliacaoDados.disciplinaId = this.filtro.disciplinaId;

        return this.criar();
      }
      return this.editar();
    },
    criar() {
      this.$store.dispatch(START_LOADING);
      AvaliacoesService.criar(this.modais.avaliacaoDados)
        .then(() => {
          this.filtrar();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.closeModal('avaliacao');
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editar() {
      this.$store.dispatch(START_LOADING);
      AvaliacoesService.editar(this.modais.avaliacaoDados)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), 'Avaliação editada!');
          this.filtrar();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.closeModal('avaliacao');
          this.$store.dispatch(STOP_LOADING);
        });
    },
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        null,
        this.$t('ACADEMICO.CONFIRMAR_EXCLUIR_AVALIACAO')
      );
      if (respostaExclusao.isConfirmed) {
        this.excluir(item);
      }
    },
    excluir(avaliacaoId) {
      this.$store.dispatch(START_LOADING);
      AvaliacoesService.excluir(avaliacaoId)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), 'Avaliação excluída!');
          this.filtrar();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.closeModal('avaliacao');
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    openModal(key, item) {
      this.modais[key] = true;
      if (!item) {
        return (this.modais[`${key}Dados`] = new Avaliacao({}));
      }
      this.modais[`${key}Dados`] = JSON.parse(JSON.stringify(item));
      this.modais[`${key}Dados`].tipoAvaliacaoId =
        this.modais[`${key}Dados`].tipoAvaliacao.id;
      return;
    },
    closeModal() {
      this.modais.avaliacao = false;
    },
    // FUNÇÕES AUXILIARES FORM
    verificarHabilitarCadastro() {
      if (!this.validarFiltro()) return (this.desabilitar.btnCadastrar = true);
      this.desabilitar.btnCadastrar = false;
    },
  },
};
</script>
