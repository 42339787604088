<template>
  <div class="row">
    <div class="col-12">
      <input-text
        ref="descricao"
        v-model="form.descricao"
        type="text"
        :maxLength="50"
        :showLengthLimit="false"
        :label="$t('ACADEMICO.NOME')"
        :placeholder="$t('ACADEMICO.NOME')"
        required
      />
    </div>
    <div class="col-12">
      <input-text
        ref="conteudoProgramatico"
        v-model="form.conteudoProgramatico"
        type="text"
        :label="$t('ACADEMICO.CONTEUDO_PROGRAMATICO')"
        :placeholder="$t('ACADEMICO.CONTEUDO_PROGRAMATICO')"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-select-search
        ref="tipoAvaliacaoId"
        :label="$t(`ACADEMICO.TIPO_AVALIACAO`)"
        :options="opcoes.tipoAvaliacao"
        v-model="form.tipoAvaliacaoId"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-number
        ref="peso"
        v-model="form.peso"
        :label="$t('ACADEMICO.PESO')"
        min="0"
        max="3"
        decimal
      />
    </div>
  <div class="col-12 col-md-6">
      <input-date-only-btn
        ref="dataAvaliacao"
        v-model="form.dataAvaliacao"
        :label="$t('ACADEMICO.DATA_AVALIACAO')"
        required
      />
    </div>
  </div>
</template>
<script>
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import TipoAvaliacaoService from '@/common/services/academico/tipoAvaliacao.service.js';
import {
  InputNumber,
  InputText,
  InputSelectSearch,
  InputDateOnlyBtn
} from '@/components/inputs';

export default {
  props: {
    form: {
      type: Object,
      default: Object,
    },
    filtro: {type: Object, default: Object}
  },
  components: {
    InputNumber,
    InputText,
    InputSelectSearch,
    InputDateOnlyBtn
  },
  mounted() {
    this.$emit('refs', this.$refs);
    this.getTipos();
  },
  data() {
    return {
      opcoes: {
        tipoAvaliacao: [],
      },
      tipoAvaliacaoAux: '',
    };
  },
  methods: {
    getTipos() {
      this.$store.dispatch(START_LOADING);
      TipoAvaliacaoService.obterPorTipoDeMediaDisciplina(this.filtro.disciplinaId, this.filtro.turmaId, this.filtro.anoLetivoId)
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.tipoAvaliacao = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>

