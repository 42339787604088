<template>
  <b-table
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    :fields="tabela.fields"
    :items="items"
    show-empty
    responsive
    striped
    hover
  >
    <template #cell(acoes)="item">
      <b-dropdown variant="outline-default">
        <b-dropdown-item @click="editar(item.item)">
          {{ $t('GERAL.EDITAR') }}
        </b-dropdown-item>
        <b-dropdown-item @click="remover(item.item)">
          {{ $t('GERAL.EXCLUIR') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>
<script>
import helpers from '@/common/utils/helpers';

export default {
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      tabela: {
        fields: [
          {
            key: 'dataAvaliacao',
            label: 'Data',
            sortable: true,
            formatter: (value) => {
              return helpers.formatarDataBr(value);
            },
          },
          {
            key: 'descricao',
            label: 'Avalição',
            sortable: true,
          },
          {
            key: 'tipoAvaliacao.descricao',
            label: 'Tipo',
            sortable: true,
          },
          {
            key: 'peso',
            label: 'Peso',
            sortable: true,
          },
          {
            key: 'acoes',
            label: 'Ações',
          },
        ],
      },
    };
  },
  methods: {
    editar(item) {
      this.$emit('editar', 'avaliacao', item);
    },
    remover(item) {
      this.$emit('excluir', item.id);
    },
  },
};
</script>
