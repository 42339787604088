<template>
  <modal
    :exibir="exibir"
    @fechar="fecharModal"
    :titulo="'Avaliação'"
    :campos="$refs"
    id="modalAvaliacao"
  >
 
    <form-avaliacao :form="form" @refs="getRefs" :filtro="filtro"/>
    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';

// Components:
import Modal from '@/components/modal/Modal.vue';
import FormAvaliacao from './Form.vue';
export default {
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
    filtro: {type: Object, default: Object}
  },
  components: {
    FormAvaliacao,
    Modal,
  },
  data() {
    return {
      refs: [],
    };
  },
  methods: {
    // FUNÇÕES VALIDAÇÃO FORMULÁRIO:
    getRefs(refs) {
      this.refs = refs;
    },
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar');
    },
    salvar() {
      if (!this.validarFormulario()) return;
      this.$emit('salvar');
    },
  },
};
</script>
